import { useContext, useState } from "react";
import { Tab, TabList, ThemeProvider } from "monday-ui-react-core";
import UserGuideForm from "../components/user-guide";
import BillingForm from "../components/billing";
import OperationCounterForm from "../components/operation-counter";
import { AppContext } from "../context/app-context";
import Intercom from "@intercom/messenger-js-sdk";

export default function DashboardPage() {
  const [currentTab, setCurrentTab] = useState(0);
  const appContext: any = useContext(AppContext);
  console.log(appContext);
  Intercom({
    app_id: "efnew8bu",
    horizontal_padding: 100,
    vertical_padding: 40,
  });
  return (
    <ThemeProvider
      systemTheme={appContext?.mondayContext?.theme}
      themeConfig={appContext?.mondayContext?.themeConfig}
    >
      <main className="mt-8 w-4/5 mx-auto">
        <div className="w-4/5 mx-auto">
          <TabList
            tabType="stretched"
            onTabChange={(tabId) => setCurrentTab(tabId)}
          >
            <Tab>User Guide</Tab>
            <Tab>Operation Counter</Tab>
            <Tab>Billing</Tab>
          </TabList>
        </div>
        <div className="mt-6">
          {currentTab === 0 && <UserGuideForm></UserGuideForm>}
          {currentTab === 1 && <OperationCounterForm></OperationCounterForm>}
          {currentTab === 2 && <BillingForm></BillingForm>}
        </div>
      </main>
    </ThemeProvider>
  );
}
